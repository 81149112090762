import React, { FC, ReactNode, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';

import { locationUtil, OrgRole, textUtil } from '@grafana/data';
import { locationService, config as runtimeConfig, getBackendSrv } from '@grafana/runtime';
import { ButtonGroup, ModalsController, ToolbarButton, PageToolbar, useForceUpdate, ButtonSelect, InlineSwitch } from '@grafana/ui';
import appEvents from 'app/core/app_events';
import config from 'app/core/config';
import { toggleKioskMode } from 'app/core/navigation/kiosk';
import { contextSrv } from 'app/core/services/context_srv';
import { DashboardCommentsModal } from 'app/features/dashboard/components/DashboardComments/DashboardCommentsModal';
import { SaveDashboardDrawer } from 'app/features/dashboard/components/SaveDashboard/SaveDashboardDrawer';
import { ShareModal } from 'app/features/dashboard/components/ShareModal';
import { playlistSrv } from 'app/features/playlist/PlaylistSrv';
import { updateTimeZoneForSession } from 'app/features/profile/state/reducers';
import { KioskMode, Layout, StoreState } from 'app/types';

import { AppEvents } from '../../../../../../packages/grafana-data/src';
import { setStarred } from '../../../../core/reducers/navBarTree';
import { getDashboardSrv } from '../../services/DashboardSrv';
import { DashboardModel } from '../../state';

import { DashNavButton } from './DashNavButton';
import { DashNavTimeControls } from './DashNavTimeControls';

const mapDispatchToProps = {
  setStarred,
  updateTimeZoneForSession,
};

const mapStateToProps = (state: StoreState) => ({
  dashboard: state.dashboard.getModel(),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface OwnProps {
  dashboard: DashboardModel;
  isFullscreen: boolean;
  kioskMode: KioskMode;
  hideTimePicker: boolean;
  folderTitle?: string;
  title: string;
  onAddPanel: () => void;
  onToggleEditLayout: (v?: boolean) => void;
  onSelectLayout: (v: any) => void;
  layout: Layout;
}

interface DashNavButtonModel {
  show: (props: Props) => boolean;
  component: FC<Partial<Props>>;
  index?: number | 'end';
}

const customLeftActions: DashNavButtonModel[] = [];
const customRightActions: DashNavButtonModel[] = [];

export function addCustomLeftAction(content: DashNavButtonModel) {
  customLeftActions.push(content);
}

export function addCustomRightAction(content: DashNavButtonModel) {
  customRightActions.push(content);
}

type Props = OwnProps & ConnectedProps<typeof connector>;

export const DashNav = React.memo<Props>((props) => {
  const forceUpdate = useForceUpdate();

  const onStarDashboard = () => {
    const dashboardSrv = getDashboardSrv();
    const { dashboard, setStarred } = props;

    dashboardSrv.starDashboard(dashboard.id, dashboard.meta.isStarred).then((newState: any) => {
      setStarred({ id: dashboard.uid, title: dashboard.title, url: dashboard.meta.url ?? '', isStarred: newState });
      dashboard.meta.isStarred = newState;
      forceUpdate();
    });
  };

  const onClose = () => {
    locationService.partial({ viewPanel: null });
  };

  const onToggleTVMode = () => {
    toggleKioskMode();
  };

  const onOpenSettings = () => {
    locationService.partial({ editview: 'settings' });
  };

  const onToggleEditLayoutClick = () => {
    const { onToggleEditLayout } = props;
    onToggleEditLayout();
  };

  const onSelectLayout = (evt: any) => {
    const { onSelectLayout } = props;
    onSelectLayout(evt.value);
  };

  const onPlaylistPrev = () => {
    playlistSrv.prev();
  };

  const onPlaylistNext = () => {
    playlistSrv.next();
  };

  const onPlaylistStop = () => {
    playlistSrv.stop();
    forceUpdate();
  };

  const addCustomContent = (actions: DashNavButtonModel[], buttons: ReactNode[]) => {
    actions.map((action, index) => {
      const Component = action.component;
      const element = <Component {...props} key={`button-custom-${index}`} />;
      typeof action.index === 'number' ? buttons.splice(action.index, 0, element) : buttons.push(element);
    });
  };

  const isPlaylistRunning = () => {
    return playlistSrv.isPlaying;
  };

  const renderLeftActionsButton = () => {
    const { dashboard, kioskMode } = props;
    const { canStar, canShare, isStarred } = dashboard.meta;
    const buttons: ReactNode[] = [];

    if (kioskMode !== KioskMode.Off || isPlaylistRunning()) {
      return [];
    }

    if (canStar) {
      let desc = isStarred ? 'Unmark as favorite' : 'Mark as favorite';
      buttons.push(
        <DashNavButton
          tooltip={desc}
          icon={isStarred ? 'favorite' : 'star'}
          iconType={isStarred ? 'mono' : 'default'}
          iconSize="lg"
          onClick={onStarDashboard}
          key="button-star"
        />
      );
    }

    if (canShare) {
      let desc = 'Share dashboard or panel';
      buttons.push(
        <ModalsController key="button-share">
          {({ showModal, hideModal }) => (
            <DashNavButton
              tooltip={desc}
              icon="share-alt"
              iconSize="lg"
              onClick={() => {
                showModal(ShareModal, {
                  dashboard,
                  onDismiss: hideModal,
                });
              }}
            />
          )}
        </ModalsController>
      );
    }

    if (dashboard.uid && config.featureToggles.dashboardComments) {
      buttons.push(
        <ModalsController key="button-dashboard-comments">
          {({ showModal, hideModal }) => (
            <DashNavButton
              tooltip="Show dashboard comments"
              icon="comment-alt-message"
              iconSize="lg"
              onClick={() => {
                showModal(DashboardCommentsModal, {
                  dashboard,
                  onDismiss: hideModal,
                });
              }}
            />
          )}
        </ModalsController>
      );
    }

    addCustomContent(customLeftActions, buttons);
    return buttons;
  };

  const renderPlaylistControls = () => {
    return (
      <ButtonGroup key="playlist-buttons">
        <ToolbarButton tooltip="Go to previous dashboard" icon="backward" onClick={onPlaylistPrev} narrow />
        <ToolbarButton onClick={onPlaylistStop}>Stop playlist</ToolbarButton>
        <ToolbarButton tooltip="Go to next dashboard" icon="forward" onClick={onPlaylistNext} narrow />
      </ButtonGroup>
    );
  };

  const renderTimeControls = () => {
    const { dashboard, updateTimeZoneForSession, hideTimePicker } = props;

    if (hideTimePicker) {
      return null;
    }

    return (
      <DashNavTimeControls dashboard={dashboard} onChangeTimeZone={updateTimeZoneForSession} key="time-controls" />
    );
  };

  type Settings = { [key: string]: { [key: string]: string } };

  const { value: settings } = useAsync(
    () => getBackendSrv().get('/api/admin/settings') as Promise<Settings>,
    []
  );

  const isEnergyEfficiencyPage = getDashboardSrv().getCurrent()?.uid === settings?.dale?.reset_calculation_feature_dashboard_id

  const [isWritingProperty, setIsWritingProperty] = useState(false)

  const writeProperty = (deviceId: string, value: number) => {
    const objectInstance = settings?.dale?.object_instance == undefined ? -1 : parseInt(settings?.dale?.object_instance, 10)
    return fetch('/api/azure-service/iothub/write-property', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "connectTimeoutInSeconds": 30,
        "methodName": "write-property",
        "objectType": "OBJECT_ANALOG_VALUE",
        "objectInstance": objectInstance,
        "deviceId": parseInt(deviceId, 10),
        "propertyId": "PROP_PRESENT_VALUE",
        "applicationTag": "BACNET_APPLICATION_TAG_REAL",
        "value": value.toString(),
        "responseTimeoutInSeconds": 300,
        "iotDeviceId": "sca-nexcom-demo",
        "moduleId": "EdgeBacnetClient"
      })
    })
  }

  const handleResetEnergyCalculation = () => {
    setIsWritingProperty(true)

    const deviceIdVar = getDashboardSrv().getCurrent()?.getVariables()?.find(x => x.id === 'deviceId')
    const deviceId = deviceIdVar?.current?.value

    let isSuccessFirstTrigger = true
    writeProperty(deviceId, 1)
      .then(res => {
        if (res.status !== 200) {
          isSuccessFirstTrigger = false
          appEvents.emit(AppEvents.alertError, [`Error ${res.status}`, res.statusText])
        }
      })
      .catch(err => appEvents.emit(AppEvents.alertError, ["Error", err.message]))
      .finally(() => {
        if (isSuccessFirstTrigger) {
          setTimeout(() => {
            writeProperty(deviceId, 0)
              .then(res => {
                if (res.status === 200) {
                  appEvents.emit(AppEvents.alertSuccess, ['Success', 'Energy calculation has been reset'])
                }
              })
              .catch(err => appEvents.emit(AppEvents.alertError, ["Error", err.message]))
              .finally(() => setIsWritingProperty(false))
          }, 2000)
        }
        else {
          setIsWritingProperty(false)
        }
      })
  }

  const renderRightActionsButton = () => {
    const { dashboard, onAddPanel, isFullscreen, kioskMode, layout } = props;
    const { canSave, canEdit, showSettings } = dashboard.meta;
    const { snapshot } = dashboard;
    const snapshotUrl = snapshot && snapshot.originalUrl;
    const buttons: ReactNode[] = [];
    const tvButton = (
      <ToolbarButton tooltip="Cycle view mode" icon="monitor" onClick={onToggleTVMode} key="tv-button" />
    );

    if (isPlaylistRunning()) {
      return [renderPlaylistControls(), renderTimeControls()];
    }

    if (kioskMode === KioskMode.TV) {
      return [renderTimeControls(), tvButton];
    }

    if (!isFullscreen && contextSrv.user.orgRole === OrgRole.Admin && isEnergyEfficiencyPage) {
      buttons.push(
        <InlineSwitch
          key="dale-write-property-switch"
          label="Reset Energy Calculation"
          showLabel={true}
          id="dale-write-property-switch"
          value={isWritingProperty}
          onChange={handleResetEnergyCalculation}
        />
      );
    }

    if (canEdit && !isFullscreen) {
      buttons.push(<ToolbarButton tooltip="Add panel" icon="panel-add" onClick={onAddPanel} key="button-panel-add" />);
    }

    if (canSave && !isFullscreen) {
      buttons.push(
        <ModalsController key="button-save">
          {({ showModal, hideModal }) => (
            <ToolbarButton
              tooltip="Save dashboard"
              icon="save"
              onClick={() => {
                showModal(SaveDashboardDrawer, {
                  dashboard,
                  onDismiss: hideModal,
                  layout,
                });
              }}
            />
          )}
        </ModalsController>
      );
    }

    if (snapshotUrl) {
      buttons.push(
        <ToolbarButton
          tooltip="Open original dashboard"
          onClick={() => gotoSnapshotOrigin(snapshotUrl)}
          icon="link"
          key="button-snapshot"
        />
      );
    }

    if (showSettings) {
      buttons.push(
        <ToolbarButton tooltip="Dashboard settings" icon="cog" onClick={onOpenSettings} key="button-settings" />
      );
    }

    if (canEdit && !isFullscreen && dashboard.independentLayout) {
      buttons.push(
        <ButtonGroup key="edit-layout">
          <ToolbarButton
            onClick={onToggleEditLayoutClick}
            icon="gf-layout-simple"
            style={layout.toggle
              ? { border: `1px solid ${runtimeConfig.theme2.colors.primary.main}` }
              : undefined}
            tooltip="Toggle layout"
            disabled={disableToggleLayout}
          ></ToolbarButton>
          <ButtonSelect
            key="edit-layout"
            style={{ display: layout.toggle ? 'inherit' : 'none' }}
            onChange={onSelectLayout}
            value={layout.toggle ? mapLayoutSelection(layout.breakpointKey) : { label: '', value: '' }}
            options={layoutSelections}
          />
        </ButtonGroup>
      );
    }

    addCustomContent(customRightActions, buttons);

    buttons.push(renderTimeControls());
    buttons.push(tvButton);
    return buttons;
  };

  // we only need to be able to edit mobile and tablet view
  // as by default the layout is desktop view
  const layoutSelections = [
    { value: 'sm', label: 'Mobile' },
    { value: 'md', label: 'Tablet' },
  ];

  const mapLayoutSelection = (breakpoint: string) => {
    const filters = layoutSelections.filter((layoutSelection) => layoutSelection.value === breakpoint);
    return filters.length > 0 ? filters[0] : layoutSelections[0];
  };

  const gotoSnapshotOrigin = (snapshotUrl: string) => {
    window.location.href = textUtil.sanitizeUrl(snapshotUrl);
  };

  const { isFullscreen, title, folderTitle } = props;
  // this ensures the component rerenders when the location changes
  const location = useLocation();
  const titleHref = locationUtil.getUrlForPartial(location, { search: 'open' });
  const parentHref = locationUtil.getUrlForPartial(location, { search: 'open', folder: 'current' });
  const onGoBack = isFullscreen ? onClose : undefined;

  // disable the toggle edit layout mode button
  const [disableToggleLayout, setDisableToggleLayout] = useState(false);
  useEffect(() => {
    const { breakpoints } = config.theme2;
    if (window.innerWidth < breakpoints.values.xxl) {
      const { onToggleEditLayout } = props;
      setDisableToggleLayout(true);
      onToggleEditLayout(true);
      return;
    }
    setDisableToggleLayout(false);
  }, []);

  return (
    <PageToolbar
      pageIcon={isFullscreen ? undefined : 'apps'}
      title={title}
      parent={folderTitle}
      titleHref={titleHref}
      parentHref={parentHref}
      onGoBack={onGoBack}
      leftItems={renderLeftActionsButton()}
    >
      {renderRightActionsButton()}
    </PageToolbar>
  );
});

DashNav.displayName = 'DashNav';

export default connector(DashNav);
