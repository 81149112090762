import { css, cx } from '@emotion/css';
import { useLingui } from '@lingui/react';
import { Item } from '@react-stately/collections';
import React from 'react';

import { GrafanaTheme2, locationUtil, NavMenuItemType, NavModelItem } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { IconName, useTheme2 } from '@grafana/ui';
//import { contextSrv } from 'app/core/services/context_srv';

import { NavBarItemMenu } from './NavBarItemMenu';
import { NavBarItemMenuTrigger } from './NavBarItemMenuTrigger';
import { getNavBarItemWithoutMenuStyles } from './NavBarItemWithoutMenu';
import { NavBarMenuItem } from './NavBarMenuItem';
//Custom Modified Component
import { NavBarMenuItemCustom } from './NavBarMenuItemCustom';
import { useNavBarContext } from './context';
import menuItemTranslations from './navBarItem-translations';
import { getNavModelItemKey } from './utils';

/* typescript-eslint:disable */
export interface Props {
  isActive?: boolean;
  isPanelPlugin?: boolean;
  folderNav?: Array<{
    id: string;
    uid: string;
    url: string;
    tags: string[];
  }>;
  className?: string;
  reverseMenuDirection?: boolean;
  link: NavModelItem;
}

const NavBarItem = ({
  isActive = false,
  className,
  reverseMenuDirection = false,
  link,
  isPanelPlugin = false,
  folderNav = [],
}: Props) => {
  const { i18n } = useLingui();
  const theme = useTheme2();
  const menuItems = link.children ?? [];
  const { menuIdOpen } = useNavBarContext();

  // Spreading `menuItems` here as otherwise we'd be mutating props
  const menuItemsSorted = reverseMenuDirection ? [...menuItems].reverse() : menuItems;
  let filteredItems = menuItemsSorted
    .filter((item) => !item.hideFromMenu)
    .filter((item) => !isPanelPlugin || folderNav?.some((f) => item.url?.includes(f.uid)))
    //Filter isProUser ( Might not be necessarry as the Grafana Teams can handle it)
    // .filter((item) => {
    //   let folderItem = folderNav.find((f) => item.url?.includes(f.uid));
    //   return (folderItem?.tags.includes('PRO') && contextSrv.user.isProUser) || !folderItem?.tags.includes('PRO');
    // })
    .map((i) => ({ ...i, menuItemType: NavMenuItemType.Item }));
  const adjustHeightForBorder = filteredItems.length === 0;
  const styles = getStyles(theme, adjustHeightForBorder, isActive);

  const findParent = (splitKey: string): string | null => {
    let parentChildItems = filteredItems.filter((i) => i.text.includes(splitKey)).map((item) => item.text);
    let parentName = parentChildItems.length > 1 ? parentChildItems[0].split(splitKey).map((s) => s.trim())[0] : null;
    return parentName;
  };
  const mapItemsToParentChild = (parentName: string) => {
    let childrenItem: NavModelItem[] = filteredItems.filter(
      (i) => i.menuItemType === NavMenuItemType.Item && i.text.includes(parentName)
    );
    if (childrenItem.length > 1) {
      filteredItems.push({
        text: parentName,
        menuItemType: NavMenuItemType.Parent,
        children: childrenItem,
      });
      //Filtered Out Child Item
      filteredItems = filteredItems.filter(
        (i) => !(i.menuItemType === NavMenuItemType.Item && i.text.includes(parentName))
      );
    }
  };

  if (isPanelPlugin) {
    let parent = findParent('-');
    if (parent) {
      //For Collapsable Section
      mapItemsToParentChild(parent);
    }
  }

  const section: NavModelItem = {
    ...link,
    children: filteredItems,
    menuItemType: NavMenuItemType.Section,
  };

  const items: NavModelItem[] = [section].concat(filteredItems);
  const onNavigate = (item: NavModelItem) => {
    const { url, target, onClick } = item;
    onClick?.();

    if (url) {
      if (!target && url.startsWith('/')) {
        locationService.push(locationUtil.stripBaseFromUrl(url));
      } else {
        window.open(url, target);
      }
    }
  };

  const translationKey = link.id && menuItemTranslations[link.id];
  const linkText = translationKey ? i18n._(translationKey) : link.text;

  return (
    <li className={cx(styles.container, { [styles.containerHover]: section.id === menuIdOpen }, className)}>
      <NavBarItemMenuTrigger
        item={section}
        isActive={isActive}
        label={linkText}
        reverseMenuDirection={reverseMenuDirection}
      >
        <NavBarItemMenu
          items={items}
          reverseMenuDirection={reverseMenuDirection}
          adjustHeightForBorder={adjustHeightForBorder}
          disabledKeys={['divider', 'subtitle']}
          aria-label={section.text}
          onNavigate={onNavigate}
        >
          {(item: NavModelItem) => {
            const translationKey = item.id && menuItemTranslations[item.id];
            const itemText = translationKey ? i18n._(translationKey) : item.text;
            const isSection = item.menuItemType === NavMenuItemType.Section;
            const isParent = item.menuItemType === NavMenuItemType.Parent;
            const icon = item.showIconInNavbar && !isSection ? (item.icon as IconName) : undefined;
            let filterFolderNav = folderNav.find((f) => item.url?.includes(f.uid));
            if (isParent && filterFolderNav === undefined && item.children!.length > 0) {
              //Try Search Child Tags and assign to parent
              filterFolderNav = folderNav.find((f) => item.children?.at(0)?.url?.includes(f.uid));
            }

            return (
              <Item key={getNavModelItemKey(item)} textValue={item.text}>
                {isPanelPlugin ? (
                  <NavBarMenuItemCustom
                    isDivider={!isSection && item.divider}
                    isSection={isSection}
                    isParent={isParent}
                    icon={icon}
                    target={item.target}
                    text={itemText}
                    url={item.url}
                    childrenItem={item.children}
                    folderInfo={filterFolderNav}
                    onClick={item.onClick}
                    styleOverrides={cx(styles.primaryText, { [styles.header]: isSection })}
                  />
                ) : (
                  <NavBarMenuItem
                    isDivider={!isSection && item.divider}
                    icon={icon}
                    target={item.target}
                    text={itemText}
                    url={item.url}
                    onClick={item.onClick}
                    styleOverrides={cx(styles.primaryText, { [styles.header]: isSection })}
                  />
                )}
              </Item>
            );
          }}
        </NavBarItemMenu>
      </NavBarItemMenuTrigger>
    </li>
  );
};

export default NavBarItem;

const getStyles = (theme: GrafanaTheme2, adjustHeightForBorder: boolean, isActive?: boolean) => ({
  ...getNavBarItemWithoutMenuStyles(theme, isActive),
  containerHover: css({
    backgroundColor: theme.colors.action.hover,
    color: theme.colors.text.primary,
  }),
  primaryText: css({
    color: theme.colors.text.primary,
  }),
  header: css({
    height: `calc(${theme.spacing(6)} - ${adjustHeightForBorder ? 2 : 1}px)`,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    whiteSpace: 'nowrap',
    width: '100%',
  }),
});
